<template>
  <div v-if="show" id="invoice" class="invoice-box" :class="{[type]: true}">

      <table class="report-container" :direction="lang == 'en'? 'ltr':''" :style="lang == 'en'? lang_dir:{}">
        <thead :class="is_repeated_header ? 'report-header' : 'no-report-header'">
        <tr>
          <th class="report-header-cell">
            <div class="header-info">
              <main-header :print-type="type" :inner-data="data" :data-header="template"></main-header>
            </div>
          </th>
        </tr>
        </thead>
        <tbody class="report-content">
        <tr>
          <td class="report-content-cell">
            <div class="main">
              <TaxInvoiceHeader  :is-multi-language-label="is_multi_language_label" :data="data" :template="template" :in-content-temp="inContentTemp"/>

              <TaxInvoiceProducts :is-multi-language-label="is_multi_language_label"
                                  :is-empty-row="is_empty_row"
                                  :items-list="items_list"
                                  :in-content-temp="inContentTemp"
                                  :in-product-table-setting="inProductTableSetting"
                                  :label-shortcut-details.sync="label_shortcut_details"
                                  :is-use-label-shortcut="is_use_label_shortcut"
                                  :is-custom-product-size-column="is_custom_product_size_column"
                                  :product-size-column-val="product_size_column_val"/>

              <TaxInvoiceLines :print-type="type" :is-multi-language-label="is_multi_language_label" :is-banking-details="is_banking_details" :is-break-before-footer="is_break_before_footer" :is-payment-details="is_payment_details" :footer-font-color="footer_font_color" :is-signature="is_signature" :data="data" :in-content-temp="inContentTemp"/>
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot :class="is_repeated_footer ? 'report-footer' : 'no-report-footer'">
        <tr>
          <td class="report-footer-cell">
            <div class="footer-info footer-print"  :class="{'page-break-inside-pdf': type == 'pdf'}">
              <div v-if="data.pos_policy">
                <p v-if="is_break_before_footer"><span :style="'border: 1px solid;width: 70%;height: 0px;display: block;margin: 20px auto 0;border-color:'+footer_font_color"></span></p>
                <div class="mb-0 ql-editor" style="float: right;text-align: right;width: 100%;height: auto;padding: 0;"  v-html="data.pos_policy"></div>
              </div>
            </div>
          </td>
        </tr>
        </tfoot>

      </table>


  </div>
</template>

<script>

import MainHeader from "@/view/content/printing-heads/MainHeader.vue";

import VueQRCodeComponent from 'vue-qrcode-component';
import TaxInvoiceProducts from '@/view/pages/modules/sales/invoices/PrintTaxInvoice/TaxInvoiceProducts.vue';
import TaxInvoiceLines from '@/view/pages/modules/sales/invoices/PrintTaxInvoice/TaxInvoiceLines.vue';
import TaxInvoiceHeader from '@/view/pages/modules/sales/invoices/PrintTaxInvoice/TaxInvoiceHeader.vue';
import html2pdf from "html2pdf.js";
import MainFooter from "@/view/content/printing-footers/MainFooter.vue";
import Vue from "vue";

export default {
  name: "print-sales-tax-invoice",
  components: {
    MainFooter,
    'qr-code': VueQRCodeComponent,
    TaxInvoiceProducts,
    TaxInvoiceLines,
    TaxInvoiceHeader,
    MainHeader
  },

  data() {
    return {
      lang: this.$i18n.locale,
      lang_dir:{'direction': 'ltr','text-align': 'left'},
      mainRoute: 'v3/sales/sales_invoices',
      templateMainRoute: 'v3/settings/print_setting/type',
      template_for: 'SalesInvoice',

      data: {},
      items_list: [],
      template: null,
      idEditing: this.$route.params.sales_invoice_id ? this.$route.params.sales_invoice_id : null,
      status: this.$route.params.status ? this.$route.params.status : null,
      items_total: 0,
      total: 0,
      show: false,
      is_show_price: (this.$route.query.is_show_price == 0) ? false : true,
      type: this.$route.params.type ? this.$route.params.type : null,
      content_temp: [],
      product_table_setting: [],
      is_multi_language_label: false,
      is_break_before_footer: false,
      is_payment_details: false,
      is_banking_details: false,
      footer_font_color: '#000',
      is_repeated_header: false,
      is_repeated_footer: false,
      is_empty_row: false,
      is_signature: false,
      is_use_label_shortcut: false,
      label_shortcut_details: [],
      is_custom_product_size_column: false,
      product_size_column_val: 37,
    }
  },
  watch: {
    items_list: {
      handler(val) {
        if (val.length > 0) {
          this.items_total = this.$_.sumBy(this.items_list, 'subtotal');
          this.items_total = this.items_total ? this.items_total : 0;
          this.total = this.items_total;

        }
      },
      deep: true
    }
  },
  computed:{
    fileName: function (){
      let _invoice_label = this.$i18n.locale ==  'ar' ? 'فاتورة مبيعات' : 'Sales invoice';
      let _customer_name = (this.data.customer && this.data.customer.fullname) ? this.data.customer.fullname : '';
      let _invoice_code = (this.data.invoice_code) ? this.data.invoice_code : '';
      return `${_invoice_label} - ${_customer_name} - ${_invoice_code}`;
    }
  },
  methods: {
    printInvoice() {
      let _id = 'invoice';
      this.exportPDF(_id);
    },
    completedForPrint(){
      let imgs = document.images,
          len = imgs.length,
          counter = 0;

      [].forEach.call( imgs, function( img ) {
        if(img.complete)
          incrementCounter();
        else
          img.addEventListener( 'load', incrementCounter, false );
      } );
      function incrementCounter() {
        counter++;
        if ( counter === len ) {
          window.print();
          // window.close();
        }
      }

    },
    exportPDF(_id) {
      let that = this;
      if (that.type == 'pdf') {
        const opt = { pagebreak: {avoid: '.tr', mode: ['css']}, margin: [5, 0, 5, 0], filename: this.fileName, image: { type: 'jpeg', quality: 2 }, html2canvas: {useCORS: true, scrollY: 0 }, jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' } };
        let _html2pdf = html2pdf().from(document.getElementById(_id)).set(opt).save();
      }else {
        this.completedForPrint();
      }
      // let _pdf = jsPdfExportTajawal("p", "px", 'a4');

      // _pdf.html(document.getElementById(_id), {
      //   margin: [2, 5, 2 ,5],
      //   autoPaging: 'text',
      //   html2canvas: {
      //     scale: 0.55,
      //     useCORS: true,
      //     allowTaint: true,
      //     // proxy: 'http://localhost:8080', imageTimeout:	0
      //   },
      //   callback: function (_pdf) {
      //     if (that.type == 'pdf') {
      //
      //       // let _imageEle = document.getElementById('rel_logo');
      //       // if (_imageEle){
      //       //   const img = new Image();
      //       //   img.src = _imageEle.src;
      //       //   img.onload = () => {
      //       //     // await for the image to be fully loaded
      //       //     _pdf.addImage(_imageEle, 'PNG', 10, 10, _imageEle.width, _imageEle.height);
      //       //     _pdf.save('invoice');
      //       //   };
      //       // }else {
      //       //   _pdf.save('invoice');
      //       // }
      //       // _pdf.save('invoice');
      //       pdf.autoPrint();
      //       pdf.output('pdfobjectnewwindow');
      //     } else {
      //       window.print();
      //       window.close();
      //     }
      //   }
      // });
    },
    async getData() {
      let _id = this.idEditing;
      await Vue.axios.get(`${this.mainRoute}/${_id}/print`).then((response) => {

        this.data = response.data.data;
        this.items_list = response.data.data.items_list;
        // this.items_list = response.data.data.items_list.map((row)=>{
        //   row.subtotal_before_tax = (((row.unit_price ? parseFloat(row.unit_price) : 0) * (row.qty ? parseFloat(row.qty) : 0)) - (row.amount_included_tax ? parseFloat(row.amount_included_tax) : 0)).toFixed(2)
        //   return row;
        // });
        this.setTitleTab();
        this.show = true;


      });
    },

    setTitleTab(){
      let _invoice_label = this.$i18n.locale ==  'ar' ? 'فاتورة مبيعات' : 'Sales invoice';
      let _customer_name = (this.data.customer && this.data.customer.fullname) ? this.data.customer.fullname : '';
      let _invoice_code = (this.data.invoice_code) ? this.data.invoice_code : '';
      document.title = `${_invoice_label} - ${_customer_name} - ${_invoice_code}`;
    },
    async printSettingBySalesInvoice() {
      await Vue.axios.get(`${this.templateMainRoute}/${this.template_for}`).then((response) => {
        this.template = response.data.data;
        this.content_temp = response.data.data.content_temp;
        this.product_table_setting = response.data.data.product_table_setting ? response.data.data.product_table_setting : [];
        this.label_shortcut_details = response.data.data.label_shortcut_details ? response.data.data.label_shortcut_details : [];
        this.is_multi_language_label = response.data.data.is_multi_language_label ? response.data.data.is_multi_language_label : false;
        this.is_break_before_footer = response.data.data.is_break_before_footer ? response.data.data.is_break_before_footer : false;
        this.is_payment_details = response.data.data.is_payment_details ? response.data.data.is_payment_details : false;
        this.is_banking_details = response.data.data.is_banking_details ? response.data.data.is_banking_details : false;
        this.footer_font_color = response.data.data.footer_font_color ? response.data.data.footer_font_color : '#000';
        this.is_repeated_header = response.data.data.is_repeated_header ? response.data.data.is_repeated_header : false;
        this.is_repeated_footer = response.data.data.is_repeated_footer ? response.data.data.is_repeated_footer : false;
        this.is_empty_row = response.data.data.is_empty_row ? response.data.data.is_empty_row : false;
        this.is_signature = response.data.data.is_signature ? response.data.data.is_signature : false;
        this.is_use_label_shortcut = response.data.data.is_use_label_shortcut ? response.data.data.is_use_label_shortcut : false;
        this.is_custom_product_size_column = response.data.data.is_custom_product_size_column ? response.data.data.is_custom_product_size_column : false;
        this.product_size_column_val = response.data.data.product_size_column_val ? response.data.data.product_size_column_val : 37;

      });
    },
    inContentTemp(content) {
      if (this.content_temp.includes(content))
        return true;
      else
        return false;
    },
    inProductTableSetting(content) {
      if (this.product_table_setting.includes(content))
        return true;
      else
        return false;
    },
  },
  mounted() {
    let promise1 = this.printSettingBySalesInvoice();
    let promise2 = this.getData();
    Promise.all([promise1, promise2]).then(() => {
      this.printInvoice();
    });
  }
}
</script>

<style>

.a4-page {
  width: 794px;
  height: auto;
  /*margin: 20px auto; */
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  font-size: 16px;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.title-1 {
  font-size: 39px;
  color: #1a5a4a;
  line-height: 1.1 !important;
}

.title-2 {
  font-size: 30px;
  color: #8898b2;
  padding-top: 8px;
}

.logo-w {
  width: 162px;
  margin-left: 77px;
}

.color-1a5a4a {
  color: #1a5a4a;
}

.green-cc {
  border: 2px solid #1a5a4a;
  padding: 2px 8px;
  border-radius: 10px;
  text-align: center;
}

.green-cc-3 {
  border: 2px solid #1a5a4a;
  padding: 10px 15px;
  border-radius: 10px;
  min-height: 85%;
}

.green-cc-2 {
  border: 2px solid #1a5a4a;
  padding: 6px 15px;
  border-radius: 10px;
}

.f-s-22px {
  font-size: 22px;
}

.f-s-19px {
  font-size: 19px;
}

.h-16px {
  line-height: 1.3;
}

.f-s-20px {
  font-size: 20px;
  font-weight: 400;
}

.f-s-15px {
  font-size: 15px;
  font-weight: 400;
}

.w-60per {
  width: 100%;
  /*display: inline-block;*/
}

.w-40per {
  width: 34%;
  display: inline-block;
}

.padding-25-15 {
  padding: 21px 15px !important;
}

.padding-b-15 {
  margin-bottom: 15px;
}

.fs-12 {
  font-size: 12px !important;
}

.word-spacing-4px {
  word-spacing: 4px;
}

.par-h {
  width: 1.5px;
  margin-left: 10px;
  background-color: #8898b2;
  display: block;
}

.qr-border {
  border: 2px solid #1a5a4a !important;
  padding: 5px;
  border-radius: 5px;
}

.total-border {
  border: 2px solid #1a5a4a !important;
  border-radius: 5px;
}

.w-330 {
  width: 55%;
}

.padding-5-20 {
  padding: 2px 5px !important;
}

.border-r-2 {
  border-right: 2px solid !important;
}

.rtl-dir {
  direction: rtl !important;
  float: right !important;
  display: flex !important;
}

#invoice {
  font-family: "AlHurra";
  width: 210mm;
  font-weight: 400 !important;
}

/*.font-family-Tajawal {*/
/*  font-family: "Roboto", sans-serif !important;*/
/*  word-spacing: initial !important;*/
/*}*/
/*#invoice * {*/
/*  word-spacing: 1px !important;*/
/*}*/

/*#invoice .space-word-2{*/
/*  word-spacing: 10px !important;*/
/*}*/
/*#invoice .space-word-15{*/
/*  word-spacing: 26px !important;*/
/*}*/
/*#invoice .space-latter-2{*/
/*  letter-spacing: 1px !important;*/
/*}*/
/*table, thead, tr, th, tbody, td {*/
/*  border: 2px solid #1a5a4a !important;*/
/*}*/

table thead tr th {
  border-bottom: 2px solid #1a5a4a !important;
}

table thead tr th:not(:last-child) {
  border-left: 2px solid #1a5a4a !important;
}

table tbody tr td:not(:last-child) {
  border-left: 2px solid #1a5a4a !important;
}

.table-border-radius {
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid #1a5a4a !important;
}

tbody td, tbody tr {
  border-bottom: 0 !important;
  border-top: 0 !important;
}

.invoice-box {
  /*background-color: #fff; */
  /* margin: auto; */
  /* padding: 30px; */
  border: 1px solid #fff;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #000;
  /* padding: 65px 40px; */
  background-color: rgb(255, 255, 255);
  width: 210mm !important;
  height: auto;
  /* margin: 20px auto; */
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 5px 20px;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  /* padding: 5px; */
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr td:nth-child(3) {
  text-align: right;
}

.invoice-box table tr.top td.title {
  font-size: 45px;
  line-height: 45px;
  color: #000;
}

.sum-sum {
  text-align: center;
  margin-left: 15px;
  min-width: 60px;
}

.padd {
  padding-bottom: 100px;

}

.invoice-box table tr.information td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  border: 1px solid #000 !important;
  background: #CAD3C8;
  border-bottom: 1px solid #fff;
  font-weight: bold;
}

.border {
  border: 1px solid #000 !important;
  padding: 5px;
}

.color {
  background-color: #CAD3C8;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/

.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
  text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}

.table thead th {
  font-size: 15px !important;
  font-weight: 400 !important;
  color: #1a5a4a;
  vertical-align: middle;
}

.width-100 {
  width: 100% !important;
}

table tbody {
  font-size: 14px !important;
}

.table thead th, .table thead td {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.table th, .table td {
  padding: 0.3rem;
}

.acc-bank-border {
  margin-bottom: 3px;
  padding-bottom: 3px;
  border-bottom: 2px solid #1a5a4a;
}

.table tbody tr:not(:last-child) {
  border-bottom: 2px solid #1a5a4a !important;
}

body > * {
  float: right !important;
  direction: rtl !important;
  text-align: right;
}

html {
  direction: rtl !important;
}
</style>
